import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios';
import { API_URL } from '../actions/constant';
import { geterrors, clearerror } from './errorReducer'



export const counterSlice = createSlice({
    name: 'bulk',
    initialState: {
        selectedArray: [],
        loading: false,
    },
    reducers: {
        checkData: (state, action) => {
            state.selectedArray = action.payload
        },
        checkAllData: (state, action) => {

        },

    },
})

export const { checkData, checkAllData } = counterSlice.actions


export default counterSlice.reducer
