import setAuthToken from '../utils/setAuthToken';
import jwt_decode from 'jwt-decode';
import axios from 'axios';
import { geterrors } from '../reducers/errorReducer';
import { setCurrentUser, registerUser } from '../reducers/authReducer';

export const login = (userData, userType) => dispatch => {
   axios.post(`/api/${userType}/login`, userData)
      .then(res => {
         // Save to LocalStorage
         const { token } = res.data;
         if (token) {
            //Set Token to Localstorage
            localStorage.setItem('jwtToken', token);
            //Set Token to Header
            setAuthToken(token);
            //Decode Token to get user Data
            const decoded = jwt_decode(token);
            //set Current User
            dispatch(setCurrentUser(decoded));
         } else {
            dispatch(registerUser(res.data));
         }
      })
      .catch(err => {
         dispatch(geterrors(err.response.data))
      })
};


export const verifyOTP = (userData, userType) => dispatch => {
   axios.post(`/api/${userType}/verifyotp`, userData)
      .then(res => {
         // Save to LocalStorage
         const { token } = res.data;
         //Set Token to Localstorage
         localStorage.setItem('jwtToken', token);
         //Set Token to Header
         setAuthToken(token);
         //Decode Token to get user Data
         const decoded = jwt_decode(token);
         //set Current User
         dispatch(setCurrentUser(decoded));

      })
      .catch(err => dispatch(geterrors(err.response.data)))
};

//Register User
export const register = (userData, userType) => dispatch => {
   axios.post(`/api/${userType}/register`, userData)
      .then(res => {
         dispatch(registerUser(res.data));
      })
      .catch(err => dispatch(geterrors(err.response.data)))
};

export const verifyEmail = (userData, userType) => dispatch => {

   axios.post(`/api/${userType}/verify`, userData)
      .then(res => {
         // Save to LocalStorage
         const { token } = res.data;
         //Set Token to Localstorage
         localStorage.setItem('jwtToken', token);
         //Set Token to Header
         setAuthToken(token);
         //Decode Token to get user Data
         const decoded = jwt_decode(token);
         //set Current User
         dispatch(setCurrentUser(decoded));
      })
      .catch(err => {
         dispatch(geterrors(err.response.data))
      })
};


//Log out users
export const logoutUser = () => dispatch => {
   // Remove Token from localstorage
   localStorage.removeItem('jwtToken');
   //Remove Auth Header for future reference
   setAuthToken(false);
   //set Current user to {} which will set isAuthenticated to false
   dispatch(setCurrentUser({}));
}
