import { createSlice } from '@reduxjs/toolkit'
import isEmpty from '../validation/is-empty';

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        isAuthenticated: false,
        user: {},
        registerDetail: {},
        verifyDetail: {}
    },
    reducers: {
        setCurrentUser: (state, action) => {
            return {
                ...state,
                isAuthenticated: !isEmpty(action.payload),
                user: action.payload
            }
        },
        registerUser: (state, action) => {
            return {
                ...state,
                registerDetail: action.payload
            }
        },
    },
})

export const { setCurrentUser, registerUser } = authSlice.actions




export default authSlice.reducer