
import axios from "axios";
// export const IMAGE_URL = 'http://localhost:5000/static/';
// export const API_URL = 'http://localhost:5000';
export const IMAGE_URL = 'https://skyclaim.co/static/';
export const API_URL = 'https://skyclaim.co';


export const UCASE = ([first, ...rest], locale = navigator.language) =>
  first.toLocaleUpperCase(locale) + rest.join('')

//APOTHEM
// export const CHAIN_ID = "51";
// export const NETWORK_NAME = "APOTHEM";
// export const EXPLORER_URL = "https://explorer.apothem.network/txs/"
// export const EXPLORER_WALLET_URL = "https://explorer.apothem.network/address/"
// export const PLUGIN_DEFI_CONTRACT = "0xb3db178db835b4dfcb4149b2161644058393267d";
// export const PAYABLE_FARM_CONTRACT = "0x73eD44604Ec5bA55cF879B564BFf33F5aca95ed5"
// export const RECEIVE_FARM_CONTRACT = "0x22c6124F5bb1FDdC34cE1e863133Df1582c9980B"

//MAINNET
export const CHAIN_ID = "50";
export const NETWORK_NAME = "MAINNET";
export const EXPLORER_URL = "https://explorer.xinfin.network/txs/"
export const EXPLORER_WALLET_URL = "https://explorer.xinfin.network/address/"
export const PLUGIN_DEFI_CONTRACT = "0xff7412ea7c8445c46a8254dfb557ac1e48094391";
export const PAYABLE_FARM_CONTRACT = "0x9e631496233b32F57fC405Be124d6eC5b8cD92a8"
export const RECEIVE_FARM_CONTRACT = "0x2a71A9fBAf4D62295825d3B8Ad55B09cA17487f3"




export const uploadFileServer = async (data) => {

  try {

    var response = await axios.post(`/upload`, data)
    return response.data.file
  } catch (err) {
    console.log("error", err)
    return null
  }

}

const sleep = (milliseconds) => {
  return new Promise(resolve => setTimeout(resolve, milliseconds))
}

export const getTxnStatus = async (txHash, provider) => {
  let transactionReceipt = null
  while (transactionReceipt == null) { // Waiting expectedBlockTime until the transaction is mined
    transactionReceipt = await provider.getTransactionReceipt(txHash);
    await sleep(3000)
  }
  if (transactionReceipt.status) {
    return [txHash, true];
  } else {
    return [txHash, false];
  }
}