import React from 'react';


export default () => {
  return (
    <div class="loaderdiv">
      <img
        className="logo-img"
        src="./images/logo-admin.png"

        alt="logo"
      />
      {/* <div class="loader" aria-hidden="true"></div> */}
    </div>);
}