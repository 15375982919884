import React, { Component, Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import setAuthToken from './utils/setAuthToken';
import { setCurrentUser } from './reducers/authReducer';
import { logoutUser } from './actions/authAction';

import axios from 'axios'
import { API_URL } from './actions/constant'


import { Provider } from 'react-redux';
import store from './store';
import './App.css';
import PrivateRoute from './components/common/PrivateRoute';
import Spinner from './components/common/Spinner';

axios.defaults.baseURL = API_URL;

const Landing = React.lazy(() => import('./components/layouts/Landing'));


//ADMIN ROUTE
const AdminLogin = React.lazy(() => import('./components/admin/auth/Login'));
const AdminVerifyOtp = React.lazy(() => import('./components/admin/auth/VerifyOtp'));


const Dashboard = React.lazy(() => import('./components/admin/dashboard/Dashboard'));

const ListClaim = React.lazy(() => import('./components/admin/claim/list/List'));

const ListPackage = React.lazy(() => import('./components/admin/package/list/List'));
const AddPackage = React.lazy(() => import('./components/admin/package/add/'));
const ViewPackage = React.lazy(() => import('./components/admin/package/view/'));


const ListInsurance = React.lazy(() => import('./components/admin/insurance/list/List'));

const ViewInvoice = React.lazy(() => import('./components/user/invoice/view/'));


const ListDeposit = React.lazy(() => import('./components/admin/deposit/list/List'));
const AddDeposit = React.lazy(() => import('./components/admin/deposit/add/'));



const Privacy = React.lazy(() => import('./components/admin/privacy/'));
const Terms = React.lazy(() => import('./components/admin/terms/'));













if (localStorage.jwtToken) {
  //Set Auth Token header Auth
  setAuthToken(localStorage.jwtToken);
  //Decode Token and get user info and exp
  const decoded = jwt_decode(localStorage.jwtToken);
  store.dispatch(setCurrentUser(decoded));
  //Check for expired token
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    //Logout user
    store.dispatch(logoutUser());
    //ToDO : clear current profile
    //Redirect to Login page
    window.location.href = '/login';
  }

}




export default function App() {
  return (
    <Provider store={store}>
      <Router
        exact
        path="/"
      >
        <Suspense fallback={<Spinner />}>
          {/* <div class="nk-app-root"> */}
          {/* ADMIN ROUTE */}
          <Routes>
            <Route index exact path="/" element={<Landing />} />
            <Route index exact path="/invoice/:id" element={<ViewInvoice />} />
            <Route exact path="/privacy" element={<Privacy />} />
            <Route exact path="/terms" element={<Terms />} />


            {/* //ADMIN ROUTE */}
            <Route index exact path="/admin/login" element={<AdminLogin />} />

            <Route index exact path="/admin/verifyotp" element={<AdminVerifyOtp />} />

            <Route path="/" element={<PrivateRoute />}>
              <Route index exact path="/admin/dashboard" element={<Dashboard />} />

              <Route index exact path="/admin/claim/list" element={<ListClaim />} />

              <Route index exact path="/admin/package/list" element={<ListPackage />} />
              <Route index exact path="/admin/package/add" element={<AddPackage />} />
              <Route index exact path="/admin/package/view/:id" element={<ViewPackage />} />


              <Route index exact path="/admin/insurance/list" element={<ListInsurance />} />


              <Route index exact path="/admin/deposit/list" element={<ListDeposit />} />
              <Route index exact path="/admin/deposit/add" element={<AddDeposit />} />

            </Route>



          </Routes>
          {/* </div> */}
        </Suspense>
      </Router>
    </Provider>
  )
}
