import { createSlice } from '@reduxjs/toolkit'



export const counterSlice = createSlice({
    name: 'web3',
    initialState: {
        provider: null,
        address: false,
        signer: null,
        web3Modal: null,
        connected: false,
        payablecontract: null,
        receivecontract: null,
        plugincontract: null
    },
    reducers: {
        clearWeb3: async (state, action) => {
            await state.web3Modal.clearCachedProvider();
            state.provider = null
            state.address = false
            state.signer = null
            state.web3Modal = null
            state.connected = false
            state.payablecontract = null
            state.receivecontract = null
            state.plugincontract = null


            window.location.reload()
        },
        setWeb3: (state, action) => {
            state.provider = action.payload.provider
            state.address = action.payload.address
            state.signer = action.payload.signer
            state.web3Modal = action.payload.web3Modal
            state.payablecontract = action.payload.payablecontract
            state.receivecontract = action.payload.receivecontract
            state.plugincontract = action.payload.plugincontract
            state.connected = true


        },

    },
})

export const { clearWeb3, setWeb3 } = counterSlice.actions



export default counterSlice.reducer
