import { configureStore } from '@reduxjs/toolkit'
import authReducer from "./reducers/authReducer"
import errorReducer from "./reducers/errorReducer"
import crudReducer from "./reducers/crudReducer"
import web3Reducer from "./reducers/web3Reducer"
import bulkReducer from "./reducers/bulkReducer"



const store = configureStore({
    reducer: {
        auth: authReducer,
        errors: errorReducer,
        crud: crudReducer,
        web3: web3Reducer,
        bulk: bulkReducer
    },
})
export default store;